import { useStaticQuery, graphql } from 'gatsby'

export const useStaticImages = () => {
  const data = useStaticQuery(graphql`
    query GET_IMAGES {
      allFile {
        edges {
          node {
            name
            childImageSharp {
              fluid {
                base64
                aspectRatio
                src
                srcSet
                srcWebp
                srcSetWebp
                sizes
              }
            }
          }
        }
      }
    }
  `)

  const images = []


  data.allFile.edges.forEach((edge) => {
    images[edge.node.name] = edge.node.childImageSharp
  })

  return images
}