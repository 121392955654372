import React, { useEffect, useRef, useContext } from 'react'
import styled from 'styled-components'
import { LoaderContext } from '../context/loader';
import { Container, Row, Col } from 'react-grid-system'
import gsap from 'gsap'
import { up } from 'styled-breakpoints'

const Wrapper = styled.div`
  position: fixed;
  width: 100%;
  height: 100vh;
  z-index: 4;
  display: flex;
  align-items: flex-end;

  ${up('sm')} {
    width: calc(100% + 15px);
    left: -15px;
    padding-left: 30px;
    padding-right: 30px;
  }
`
const Background = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: black;
  z-index: -1;
`
const Content = styled.div`
  color: white;
  padding-bottom: 30px;
  text-transform: uppercase;

  div {
    opacity: 0;
    transform: translateY(30px);
  }
`
const Title = styled.div`
  font-family: ${props => props.theme.fonts.domaine};
  font-weight: 400;
  color: white;
  margin-bottom: 14px;
  opacity: 0;
  line-height: 1;
  font-size: 60px;

  ${up('sm')} {
    font-size: 110px;
  }

  ${up('md')} {
    font-size: 144px;
  }
`

const Loader = () => {
  const context = useContext(LoaderContext)
  const wrapper = useRef()
  const background = useRef()
  const title = useRef()
  const line1 = useRef()
  const line2 = useRef()
  const line3 = useRef()

	useEffect(() => {
    document.body.style.overflow = 'hidden'
    appear().then(() => {
      document.body.style.overflow = 'auto'
      wrapper.current.style.display = 'none'
      context.setLoading(false)
      window.dispatchEvent(new CustomEvent('loader-end'))
    })
  })

  const appear = () => {
    return new Promise((resolve) => {
      const tl = gsap.timeline({
        onComplete: resolve
      })
  
      tl.to(title.current, {
        delay: 0.4,
        alpha: 1,
        color: '#d0021b',
        duration: 1,
        ease: 'power2.out'
      })
  
      tl.to([line1.current, line2.current ,line3.current], {
        stagger: 0.1,
        alpha: 1,
        y: 0,
        ease: 'power2.out'
      }, '-=0.3')
  
      tl.to([line1.current, line2.current ,line3.current], {
        delay: 0.3,
        stagger: 0.1,
        alpha: 0,
        y: -30,
        ease: 'power2.in'
      })
  
      tl.to(title.current, {
        alpha: 0,
        color: 'white',
        duration: 1,
        ease: 'power2.out'
      })
  
      tl.to(background.current, {
        alpha: 0,
        duration: 0.4,
        ease: 'power2.out'
      })
    })
  }

  return (
    <Wrapper ref={wrapper}>
      <Background ref={background}/>
      <div style={{ width: '100%' }}>
        <Container fluid>
          <Row align='end'>
            <Col xs='content'><Title ref={title}>Edwin Contat</Title></Col>
            <Col xs='content'>
              <Content>
                <div ref={line1}>Digital Art Director</div>
                <div ref={line2}>LYON,France</div>
                <div ref={line3}>2021</div>
              </Content>
            </Col>
          </Row>
        </Container>
      </div>
    </Wrapper>
  )
}

export default Loader