const globalVariables = {
  breakpoints: {
    sm: '768px',
    md: '1024px',
    lg: '1200px',
    xl: '1400px',
    xxl: '1600px'
  },
  eases: {
    out: `cubic-bezier(0.23, 1, 0.32, 1)`
  },
  fonts: {
    domaine: `'Domaine Display Condensed', sans-serif`,
    grotesk: `'Space Grotesk', 'sans-serif'`
  },
  colors: {
    red: `#d0021b`
  }
}

export default globalVariables