import '../static/font.css'
import { createGlobalStyle } from 'styled-components'
import { up } from 'styled-breakpoints'

const GlobalStyles = createGlobalStyle`

  body {
    font-family: ${props => props.theme.fonts.grotesk};
    font-weight: 400;
    margin: 0;
    padding: 0;
    font-size: 12px;
    line-height: 15px;
  }

  .page {
    position: relative;
    z-index: 3;
  }

  .f12 {
    font-size: 12px;
    line-height: 15px;
  }

  .title {
    position: relative;
    z-index: 2;
    margin: 0;
    font-family: ${props => props.theme.fonts.domaine};
    font-weight: 400;
    color: ${props => props.theme.colors.red};
    font-size: 50px;
    line-height: 60px;

    ${up('xxl')} {
      font-size: 64px;
      line-height: 74px;
    }
  }
`

export default GlobalStyles